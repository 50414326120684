import { useAuthEnabled, useSignInAlias } from '../../index';
import { Box, Divider, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SfhTextField } from 'modules/picasso-ui/form/settingsForm/SettingsFormHookForm';
import { useForm } from 'react-hook-form';
import { stringNotEmpty } from 'utils/yupUtils';
import * as yup from 'yup';
import ProgressButton from 'modules/picasso-ui/form/ProgressButton';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import dynamic from 'next/dynamic';
import { useApp } from 'modules/yoio/useApp';

const FirebaseAuth = dynamic(() =>
  import('modules/react-auth/firebase/FirebaseAuth').then((mod) => mod.FirebaseAuth)
)


const useStyles = makeStyles((theme)=>({
  root: {
    '& .mdl-shadow--2dp': {
      boxShadow: 'none !important'
    }
  },
  adornment: {
    color: theme.palette.text.secondary
  }
}));

export default function LoginWidget({signInSuccessUrl}) {

  const classes = useStyles();

  const authEnabled = useAuthEnabled();

  const { app } = useApp()

  return (
    <>
      {authEnabled === false && <>Auth disabled</>}
      {authEnabled === true && 
        <div className={classes.root}>
          <FirebaseAuth signInSuccessUrl={signInSuccessUrl}/>
          
            <Box style={{width: '300px', margin: 'auto'}}>
              <Box textAlign="center" mt={3} mb={3}>
                <Divider />
              </Box>
              <UsernameLogin signInSuccessUrl={app.afterLoginUrl}/> {/* on a username sign in, directly go to afterLogin, because then user has an account already */}
            </Box>
        </div>
      }
    </>
  );

}

const schema = yup.object().shape({
  alias: stringNotEmpty('Email'), //don't validate for email, because for testing the login can be something as "beta:alex"
  password: stringNotEmpty('Password'),
});

const UsernameLogin = ({signInSuccessUrl}) => {

  const classes = useStyles()

  const [submitting, setSubmitting] = useState(false)

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        alias: '',
        password: '',
    }
  });

  const { signIn } = useSignInAlias()

  const onSubmit = (data) => {
    setSubmitting(true)

    if (data.alias) {
      data.alias = data.alias.trim()
    }

    signIn(data, signInSuccessUrl)
    .catch((e)=> {
      setSubmitting(false)
    })
  }

  return (
    <>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={1}>
          <SfhTextField
            name="alias"
            control={control}
            errors={errors}
            textFieldProps={{
              //size: 'medium',
              placeholder: 'Email',
              InputProps:{
                startAdornment: (
                  <InputAdornment position="start" className={classes.adornment}>
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              },
            }}
            onBeforeChange={e=>{
              //trim whitespaces.
              if (e.target.value.includes(' ')) {
                e.target.value = e.target.value.replace(/\s/g, '')
              }
            }}
          />
        </Box>

        <Box mb={1}>
          <SfhTextField
            name="password"
            control={control}
            errors={errors}
            textFieldProps={{
              //size: 'medium',
              placeholder: 'Password',
              type: 'password',
              InputProps:{
                startAdornment: (
                  <InputAdornment position="start" className={classes.adornment}>
                    <VpnKeyOutlinedIcon />
                  </InputAdornment>
                ),
              }
            }}
          />
        </Box>

        <Box>
          <ProgressButton 
              color="primary" 
              variant="contained" 
              type="submit"
              fullWidth={true}
              size="large"
              loading={submitting}
            >
              Login
            </ProgressButton>
        </Box>
      </form>

    </>
  )

}