import { LoginScreen } from "modules/yoio/screen/LoginScreen";
import { handleLoginPageRequest } from "modules/yoio-modules/core/pageRequestHandler";

export default function Page() {

  return(
      <LoginScreen />
  )

}

export async function getServerSideProps(context) {

  return await handleLoginPageRequest(context)

}