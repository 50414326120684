import { useSignedIn } from "modules/react-auth";
import { useApp } from "modules/yoio/useApp";
import { useRouter } from "next/router";
import { useEffect } from "react";

/**
 * 
 * If the user seems to be signed in AND has an account, this will redirect him into his workspace.
 * 
 * Attention: it dows not validate if the user session is valid. 
 * The validation is the responsibility of the workspace.
 * 
 * (however, it uses "hasAccount" and the current implementation of hasAccount loads the account from
 * the api which is a trustful information. this can change in the future, therefore not rely on that here)
 * 
 * @returns 
 * 
 */
const redirectUserThatSeemsSignedInAndHasAccount = () => {

    const { app } = useApp()

    const { probablySignedIn, hasAccount, hasWorkspaceContext } = useSignedIn()

    const router = useRouter()

    useEffect(() => {
        if (router.isReady) {
            if(probablySignedIn === true && hasAccount === true && hasWorkspaceContext){
                router.replace(app.afterLoginUrl)
            }
        }
    }, [router.isReady, probablySignedIn, hasAccount]);

    return {probablySignedIn, hasAccount, hasWorkspaceContext};

}

export const ClientSessionDataEmptyRedirectBorder = ({children}) => {

    const { probablySignedIn, hasAccount, hasWorkspaceContext } = redirectUserThatSeemsSignedInAndHasAccount()

    return (
        <>
            {(probablySignedIn === false || hasAccount === false || hasWorkspaceContext === false) && (
                <>{children}</>
            )}
        </>
    )

}