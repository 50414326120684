import { Box, Typography } from "@mui/material";
import { LegalLinksBar } from "modules/plinzip/component/LegalLinksBar";
import { CenteredContentScaffold } from "../scaffold/CenteredContentScaffold";

export const OnboardingFlowScaffold = ({children, heading}) => {

    return (
        <CenteredContentScaffold fullHeight={true} bottomElem={<LegalLinksBar />} showAppLogo={false}>
            <Box flexGrow="1" display="flex" minHeight="0px" flexDirection="column">
                <Box textAlign="center" mb={8}>
                    <Typography variant="h2" style={{fontSize: '28px', fontWeight: '500'}}>{heading}</Typography>
                </Box>
                <Box textAlign="center" display="flex" flexGrow="1" alignItems="stretch">
                    <Box width="100%">
                        {children}
                    </Box>
                </Box>
            </Box>
        </CenteredContentScaffold>
    )

}