import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { LinkUnderlined } from "modules/picasso-ui/link/LinkUnderlined"
import { useTranslation } from "next-i18next"
import { appRoutes } from "utils/appRoutes"

const useStyles = makeStyles(()=> ({
    footer: {
    }
}))

export const LegalLinksBar = () => {

    const classes = useStyles()

    const { t } = useTranslation('yoio')

    return (
      <div className={classes.footer}>
            <Typography style={{
              fontSize: '12px',
            }}>
              <LinkUnderlined href={appRoutes.legal.privacyPolicy.buildPath()} target="_blank">{t('legal.privacyPolicy.title')}</LinkUnderlined>
              <LinkUnderlined href={appRoutes.legal.terms.buildPath()} target="_blank">{t('legal.terms.title')}</LinkUnderlined>
              <LinkUnderlined href={appRoutes.legal.legalNotice.buildPath()} target="_blank">{t('legal.legalNotice.title')}</LinkUnderlined>
            </Typography>
      </div>
    )
  
}