import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Link from "next/link"


const useStyles = makeStyles((theme)=>({
    link: {
        color: theme.palette.text.secondary,
        textDecoration: 'underline',
        '&:hover': {
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
        },
        marginBottom: theme.spacing(0),
        fontSize: '12px',
        '& > *': {
            fontSize: '12px',
        },
        '&+$link': {
            marginLeft: theme.spacing(3)
        },
    },
}))

export const LinkUnderlined = ({href, children, target}) => {

    const classes = useStyles()

    return (
        <Link href={href} style={{display: 'inline-block'}}>
            <a className={classes.link} target={target}>
                <Typography component="span">{children}</Typography>
            </a>
        </Link>
    )
    
}
