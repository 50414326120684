import LoginWidget from 'modules/react-auth/component/flow/LoginWidget';
import { Alert, Box, Typography } from '@mui/material';
import { OnboardingFlowScaffold } from 'modules/yoio/onboarding/OnboardingFlowScaffold';
import { LinkNormal } from 'modules/picasso-ui/link/LinkNormal';
import { ClientSessionDataEmptyRedirectBorder } from '../../react-auth/component/border/ClientSessionDataEmptyRedirectBorder';
import { useTranslation } from 'next-i18next';
import { appRoutes } from 'utils/appRoutes';
import { usePageNavigateState } from 'modules/picasso-ui/menu/PageNavigateState';
import { useApp } from '../useApp';

export const LoginScreen = () => {

  const { t } = useTranslation('yoio')

  const { app } = useApp()

  const navState = usePageNavigateState()

  const { afterPasswordReset, afterPasswordResetNew } = navState || {}

  return (
    <>
      <ClientSessionDataEmptyRedirectBorder>
          {afterPasswordReset && 
            <Box display="flex" justifyContent="center" mt={3}>
              <Alert severity="info" sx={{maxWidth: '600px'}}>
                  <Box textAlign="left">
                      <Typography>{t('yoio:access.passwordReset.doneText')}</Typography>
                  </Box>
              </Alert>
            </Box>
          }
          {afterPasswordResetNew && 
            <Box display="flex" justifyContent="center" mt={3}>
              <Alert severity="info" sx={{maxWidth: '600px'}}>
                  <Box textAlign="left">
                      <Typography>{t('yoio:access.passwordReset.changedDoneText')}</Typography>
                  </Box>
              </Alert>
            </Box>
          }
          <OnboardingFlowScaffold heading={t('yoio:access.login.loginText')}>
            <LoginWidget />

            <Box mt={1}>
              <LinkNormal href={appRoutes.yoio.access.passwordReset.buildPath()}>
                  {t('yoio:access.passwordReset.linkTitle')}
              </LinkNormal>
            </Box>

            {app.signupEnabled === true &&
              <Box mt={8}>
                <LinkNormal startText={t('yoio:access.signup.noAccountYet')} href={appRoutes.yoio.access.signup.buildPath()}>
                  {t('yoio:access.signup.title')}
                </LinkNormal>
              </Box>
            }

          </OnboardingFlowScaffold>
      </ClientSessionDataEmptyRedirectBorder>
    </>
  );
}
